@media only screen  and (min-width: 1100px) {
    .overlay {
        top: 50px;
    }
    .btn-export {
    padding-top: 2px !important;
    }
}

@media only screen  and (max-width: 1100px) {
    .overlay {
        top: 50px;
    }
}

@media only screen  and (max-width: 980px) {
    .overlay {
        top: 50px;
    }
    .progress-bar-card {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .content {
        width: auto;
        white-space: nowrap;
    }

    .progress-item {
        float: inherit;
        width: 100px;
        display: inline-block !important;
    }

    .progress-item:first-child {
        width: 100px;
        margin-left: 20px;
    }

    .progress-item:last-child {
        width: 70px;
    }

    .left-line.first {
        display: inline-block;
    }

    .mandatory {
        display: none;
    }

    .otp-input {
        text-align: left;
        width: 400px !important;
    }

    .alert-danger {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    /*header style*/
    .header-logo {
        display: none !important;
    }

    .header.show .heading-text {
        display: none;
    }

    /* header.header.show {
        width: calc(100% - 260px) !important;
    } */

    header.header.show + .aside + .screens {
        width: 100% !important;
    }

    .aside {
        height: 100vh;
        overflow: scroll;
    }

    .MuiGrid-spacing-xs-5 {
        margin: 0 -20px !important;
    }

    .MuiGrid-spacing-xs-5 > .MuiGrid-item {
        padding: 10px !important;
    }

    .input-height .MuiGrid-root.MuiGrid-container.MuiGrid-item {
        margin-bottom: 10px !important;
    }
    .MuiGrid-grid-xs-6{
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .defalut-loader-screen{
        padding: 0 15px;
      }
}

@media only screen  and (max-width: 830px) {
    .overlay {
        top: 50px;
    }
}

@media only screen  and (max-width: 768px) {
    .overlay {
        top: 50px;
    }
}

@media only screen  and (max-width: 583px) {
    .overlay {
        top: 72px;
    }
}

@media only screen  and (max-width: 480px) {
    .overlay {
        top: 72px;
    }
}

@media only screen  and (max-width: 380px) {
    .overlay {
        top: 72px;
    }
}

@media only screen  and (max-width: 300px) {
.zingitLogo {
    width: 63.33px;
    margin-left: 0px;
    margin-right: 5px;
}
.nav-item a {
    width: 63px;
    font-size: 10px !important;
    padding-left: 4px;
}
.pcMenu #basic-nav-dropdown{
    width: 70px;
    font-size: 7px !important;
}
.nav-item.drop-down-item {
    font-size: 9px !important;
}
.thumbnail-image {
    width: 17px;
    margin-left: 8px;
}
.overlay {
    top: 58px;
    }
.popup-content {
  width: 247px;
  height: 144px;
}
.popup-header {
  position: absolute;
  width: 247px;
  height: 50px;
}
.popup-header p {
  width: 247px;
  height: 25px;
  top: 12px;
  left: 9px;
  font-size: 14px;
}
.popup-body {
  width: 60%;
  height: 44%;
  left: 20px;
  top: 60px;
}
.radio-form {
  width: 247px;
  height: 27px;
  font-size: 12px;
}
.btn-export {
    width: 75px;
    height: 30px;
    left: 140px;
    top: 48px;
    padding-top: 0px !important;
    padding-left: 5px !important;
}
.close-popup {
  top: 15px;
  margin-left: 200px;
  width: 25px;
}
}
@media only screen and (max-width: 1024px) {
    .login-mainpage {
        margin: 30px 0;
    }

    .auth-form {
        margin: 0 auto;
    }
    .header {
        padding-left: 0px;
        z-index: 9;
      }
}

@media only screen and (max-width: 830px) {
    .login-mainpage {
        width: 100%;
        margin: 40px auto;
    }

    .auth-form {
        width: 430px;
        padding: 20px;
        margin: 40px auto;
    }
    .header {
        padding-left: 0px;
        position: fixed;
        z-index: 9;
        top:0;
      }
}

@media only screen and (max-width: 767px) {
    .login-mainpage {
        width: 100%;
        margin: 40px auto;
    }

    .auth-form {
        width: 430px;
        padding: 20px;
        margin: 40px auto;
    }
    .header {
        padding-left: 0px;
        position: fixed;
        z-index: 9;
        top:0;
      }
}

@media only screen and (max-width: 540px) {
    .login-mainpage {
        width: 100%;
        margin: 90px auto;
    }

    .auth-form {
        width: 95%;
        padding: 20px;
        margin: 40px auto;
    }
}