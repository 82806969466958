body {
  margin: 0;
  font-family: 'Open Sans', helvetica, arial ,sans-serif ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans',helvetica,arial,sans-serif;
}
