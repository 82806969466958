.aside-header {
  width: 100%;
  height: 65px;
  display: block;
  align-items: center;
  padding: 18px;
}

.zingitLogo {
  margin-left: 8px;
  width: 34px;
}
.zingitLogo-login {
  margin-left: 8px;
  width: 42px;
}

.aside li {
  padding-left: 14px;
  font-size: 12px !important;
}

.SubMenus li {
  background: #e9f7ff;
}

.aside li.active {
  background-color: #0079c1 !important;
}

.aside .down-arrow img {
  position: absolute;

  left: 257.86px;
  top: 137px;
}

.down-arrow {
  cursor: pointer;
}
.aside {
  position: absolute;
  width: 300px;

  position: absolute;
  width: 270px;
  top: 0;
  left: -270px;
  z-index: 999;

  box-sizing: border-box;
}

.aside.asideCollapse {
  box-shadow: none;
}

.aside ul {
  margin: 0 !important;
  padding: 0 !important;
}

.aside ul li {
  list-style: none;
  height: 50px;
  margin-bottom: 2px;
  border-bottom: solid 1px #dadada;
}

.aside-link a {
  float: left;
  width: 100%;
  height: 100%;
  line-height: 50px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  padding-left: 10px;
}

.aside-link a:hover {
  color: #000 !important;
  text-decoration: none;
}

.aside-link.active a {
  background: #0079c1;
  color: #fff;
}

.aside-link.active a:hover {
  color: #fff !important;
}
.aside-margin {
  margin-left: 25px;
}

.aside-link a span {
  width: 30px;
  height: 30px;
  float: left;
  background-color: #ffffff;
  border: 4px solid #ddd;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px 10px 0;
}

.aside-link.active a span {
  background: #0079c1;
  border-color: #2b918b;
}

.aside-link img {
  margin-right: 18px;
  color: #000;
}

.aside-link li {
  padding-left: 20px;
}

.hide {
  display: none !important;
}

.aside-link.aside-margin.sideactive a {
  font-weight: bold;
}

.sideCollapse {
  display: none !important;
}

header.header.show + .aside.asideCollapse {
  height: auto;
}
