/*Global Css*/

html,
body {
  float: left;
  font-family: "Open Sans", helvetica, arial, sans-serif !important;
  letter-spacing: 1.23288px;
  width: 100%;
  overflow-x: hidden;
  background: #f7f7f7;
}

/*Global Style*/
ul {
  margin: 0 !important;
  padding: 0 !important;
}

li {
  list-style: none;
}

p {
  font-size: 12px;
  color: #666060;
  margin: 0 !important;
  padding: 0;
}

span {
  color: #000;
}

a:hover {
  text-decoration: none !important;
}

.content-container {
  float: left;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #efeff9;
}

button,
.default-button span,
.dark-button span {
  color: #ffffff !important;
}

button:focus,
button:hover,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.default-login-button {
  height: 60px;
  line-height: 34px;
  padding: 14px 30px;
  background: #E6FB04;
  border-color: #E6FB04;
  color: #000000 !important;
  font-size: 16px !important;
  border: none;
  font-weight: 500;
  border-radius: 5px;
  min-width: 140px;
  display: flex;
  justify-content: center;
}

.default-button {
  height: 40px;
  line-height: 34px;
  padding: 2px 30px;
  background: #5cb85c;
  border-color: #5cb85c;
  color: #ffffff !important;
  font-size: 18px !important;
  border: none;
  border-radius: 5px;
  min-width: 140px;
  display: flex;
  justify-content: center;
}

.default-button :hover {
  background-color: #398439;
  border-color: #398439;
}

.full {
  width: 100%;
}

.cancel-button {
  height: 34px;
  line-height: 34px;
  padding: 0 30px;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  color: #000000 !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  min-width: 140px;
  display: flex;
  justify-content: center;
}

.nav-button button {
  border: none;
  background-color: #fff;
}

.nav-button {
  float: left;
  margin-top: 6px;
}

.transparent-button {
  background: transparent !important;
  border-color: transparent !important;
  color: #908f8d !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.blue-button {
  height: 34px;
  line-height: 34px;
  padding: 0 30px;
  background: #357ffa !important;
  border-color: #357ffa !important;
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  min-width: 140px;
  display: flex;
  justify-content: center;
}

.orange-button {
  height: 34px;
  line-height: 34px;
  padding: 0 30px;
  background: #f2682a;
  border-color: #f2682a !important;
  color: #ffffff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 5px;
  min-width: 140px;
  display: flex;
  justify-content: center;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-contained.dark-button.mr-2.MuiButton-containedSecondary {
  background-color: #656461 !important;
  border-color: #656461 !important;
  color: #ffffff !important;
}

.field-label {
  color: #7d7d7d;
  font-size: 15px;
}

.helper-text {
  color: rgba(0, 0, 0, 0.54);
  font-size: 0.75rem;
  margin: 3px 0 0;
  text-align: left;
  font-family: "Open Sans", helvetica, arial, sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

/*auth screen*/

.auth-screen {
  background-color: #f7f7f7 !important;
  width: 100%;
  display: flex;
  justify-content: center;
}

.main-page {
  padding: 20px;
  background-color: #fff !important;
}

.back-image {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.zingit-logo img{height: 40px; padding: 0px 0px;}

.auth-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 99;
}

.auth-form {
  width: 430px;
  padding: 35px 20px;
  background-color: #ffffff;
  margin-top: 49px;
  border: 1px solid #8692A6;
  display: inline-block;
  border-radius: 4px;
}

.auth-form h5 {
  padding-top: 25px;
  padding-bottom: 0px;
  font-weight: 500 !important;
  font-size: 20px !important;
  color: #000000;
  letter-spacing: 0.5px;
  font-family: "Lato", sans-serif !important;
}

.auth-form p {
  font-size: 15px;
}

.form-body .form-label {
  color: #000 !important;
  font-weight: 300 !important;
}

.forgot {
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}

/*Header Section*/
.mainheader {
  float: left;
  width: 100%;
  background: #000000;
  padding: 0px 15px;
}

.mainheader a {
  color: #fff;
  font-size: 15px !important;
  letter-spacing: 0.5px;
}
/* 
.mainheader :hover {
  color: #fff !important;
} */

.header {
  float: left;
  width: 100%;
  background: #000000;
  padding: 10px 15px;
  
}

.header a {
  color: #fff;
  font-size: 15px !important;
  letter-spacing: 0.5px;
}

.header :hover {
  color: #fff !important;
}

.screen-header {
  background-color: transparent;
}

.theme-header + span {
  font-size: 14px;
  font-weight: 600;
  color: #383850;
}

/*Screens*/

.screens {
  float: right;
  width: 100%;
  height: calc(100vh - 56px);
  overflow: scroll;
  padding: 15px;
  box-sizing: border-box;
  background-color: #f7f7f7;
}

.progress-bar-card {
  float: left;
  width: 100%;
  height: 60px;
  align-items: center;
  background: #ffffff;
  margin-bottom: 20px;
}

.progress-item {
  float: left;
  width: 10%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  position: relative;
}

.progress-item:first-child,
.progress-item:last-child {
  width: 5%;
}

.count-col {
  float: left;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 4px solid #dddddd;
  background: #ffffff;
  font-size: 13px;
  position: relative;
  z-index: 9;
  color: #787878;
}

.left-line {
  float: left;
  width: 50%;
  height: 4px;
  left: 0;
  top: 19px;
  background: #dddddd;
  position: absolute;
}

.left-line.first {
  display: none;
}

.right-line {
  float: left;
  width: 50%;
  height: 4px;
  left: inherit;
  right: 0;
  top: 19px;
  background: #dddddd;
  position: absolute;
}

.right-line.last {
  display: none;
}

.progress-item.done .count-col {
  background: #3eb0a9;
  border-color: #3eb0a9;
  color: #ffffff;
}

.progress-item.active .count-col {
  background: #ffffff;
  border-color: #3eb0a9;
}

.progress-item.done .left-line,
.progress-item.done .right-line,
.progress-item.active .left-line,
.progress-item.active .right-line {
  background: #3eb0a9;
}

.span-label {
  font-size: 13px !important;
}

.span-helper-text {
  font-size: 10px;
  color: #a1a1a1;
}

label.upload-box {
  width: 230px;
  height: 57px;
  background: #f9fcff;
  border: 1px dashed #71aff9;
  border-radius: 5px;
  box-sizing: border-box;
}

span.upload-icon {
  float: left;
  width: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px dashed #71aff9;
  box-sizing: border-box;
}

.upload-content {
  float: left;
  width: calc(100% - 50px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  color: #71aff9;
}

/*FOOTER SECTION*/

.footer {
  float: left;
  width: 100%;
  height: 60px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #dddddd !important;
  font-size: 14px;
}

.social_icons {
  margin-bottom: 10px !important;
}

.social_icons img {
  width: 20px;
}

.modal-body p {
  line-height: 30px;
}

.payment-col {
  float: left;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dddddd;
  font-size: 13px;
}

.payment-col span:first-child {
  color: #3eb0a9;
  margin-right: 20px;
}

.user-drop {
  color: #fff !important;
}

.user-drop a {
  display: flex;
  align-items: center;
  font-size: 15px;
}

/*Bootstrp Customize*/

.card,
.card-header,
.card-footer {
  border-radius: 0 !important;
}

.card {
  box-shadow: 0 0 7px 0 rgba(235, 235, 235, 0.27058823529411763);
}

.card-header {
  font-size: 16px !important;
  padding-left: 0px !important;
  color: #333;
  font-weight: bold;
  background-color: transparent !important;
}

.card-header .mandatory {
  display: flex;
  align-items: center;
  color: #ce1815 !important;
}

.card-header .sub {
  font-size: 10px;
  color: #a1a1a1;
}

.modal-dialog {
  max-width: 400px !important;
}

.card .card {
  padding: 20px;
}

.alert-danger {
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  border: 1px solid #721c24 !important;
}

.alert-danger .alert-heading,
.alert-danger span {
  font-size: 14px;
  margin: 0 !important;
}

.dropdown-menu.show {
  left: inherit;
  right: 0;
}
.dropdown-menu {
  /* top: 42px !important; */
  padding: 0px !important;
  /* margin-top: 8px !important; */
  right: 5px;
  border-radius: 0 !important;
  border: 0px !important;
  border-top: #E6FB04 4px solid !important;
}

.form-label {
  color: #fff !important;
  font-weight: bold !important;
  font-size: 14px;
  font-weight: 500;
}

.form-control {
  font-size: 13px;
  /*background-color: #efeff9 !important;*/
}
.overlay-forms-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0.75rem;
    height:35px;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}
.overlay-forms-field.missedAppt{
  height: 0px;
  border: none;
}
.overlay-forms-field .input-group-text {
  white-space: none !important;
  font-size: 14px !important;
  overflow: hidden !important;
  height: 34px;
  border:none;
  background: transparent;
  padding: 0;
  width:100%;
}
.overlay-forms-field .missedAppt-textarea {
  margin-top: 110px;
  margin-bottom: 2px;
  resize: none;
}
.input-group-text {
  white-space: none !important;
  font-size: 14px !important;
  overflow: hidden !important;
  height: 34px;
}
.edit-icon {
  position: absolute;
  top: 36px;
  right: 10px;
}

/*On State Change Style*/

header.header.show {
  float: right;
}
.notification-buttons{
  width:100%;
  margin-top:.5rem;
}
.notification-buttons.missedAppt{
  position: relative;
  top: 110px;
  margin-bottom: 120px;
}
.notification-buttons .overlay-cancel{
  background: transparent;
  border:none;
  color:#0079c1 !important;
  font-size: 14px;
  font-weight: 500;
  width: 90px;
    height: 32px;
}
.notification-buttons .overlay-Save{
  background: #0079c1;
  border:none;
  color:#ffffff;
  font-size: 14px;
  font-weight: 500;
  width: 90px;
    height: 32px;
    border-radius: 3px;
}
/* header.header.show + .aside {
  display: block;
  width: 300px !important;
  height: 100vh;
  left: 0 !important;
  z-index: 99;
  background-color: #ffff;
} */

header.header.show + .aside + .screens {
  width: 100% !important;
}

header.header.show + .aside.asideCollapse + .screens {
  width: 100% !important;
}

.table {
  background-color: #fff;
}

.table-padded {
  border-collapse: separate;
  border-spacing: 0 10px;
}

.table-padded thead tr {
  background: #f8f8f8;
  font-size: 14px;
  color: #717171 !important;
  text-transform: uppercase;
}

.table thead th {
  font-weight: 500;
  font-family: "Open Sans", helvetica, arial, sans-serif;
}


.table tbody td {
  font-size: 14px !important;
}

.table tbody ul li {
  display: inline-block;
}

.table-padded tr {
  background: #ffffff;
}

.table-padded tbody tr:hover {
  box-shadow: 0 7px 8px 1px rgba(219, 219, 219, 0.4);
}

.table td,
.table th {
  vertical-align: middle !important;
  padding: 14px 0.75rem !important;
}

.custom-radio {
  margin-right: 15px;
}

.custom-radio .active {
  display: none;
}

.custom-radio img {
  margin-right: 5px;
}

.custom-radio span {
  font-size: 13px;
}

input:checked + label .active {
  display: inline-block !important;
}

input:checked + label .inactive {
  display: none !important;
}

.modal-title {
  font-size: 20px;
}

.otp-input {
  width: 400px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 3px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

span.onn,
span.off {
  color: #787878;
  position: absolute;
  left: 50px;
  font-size: 13px;
  font-weight: 600;
  white-space: nowrap;
}

span.onn {
  display: none;
}

input:checked ~ span.off {
  display: none !important;
}

input:checked ~ span.onn {
  display: inline-block !important;
}

.icon-box {
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #23475f;
  margin-right: 10px;
}

.form-error {
  position: absolute;
  left: 0;
  top: -35px;
  font-size: 13px;
  color: #ff0000;
  padding-top: 10px;
}

.loading-bar {
  float: left;
  width: 100% !important;
  height: calc(100% - 100px);
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.loading-bar-numbers {
  float: left;
  width: 100% !important;
  height: calc(30% - 100px) !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

span.form-control {
  font-size: 14px;
}

.react-confirm-alert-overlay {
  background-color: rgba(5, 5, 5, 0.9) !important;
}

.react-confirm-alert-body {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center !important;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
  font-size: 25px;
  font-weight: 700;
  color: #282828;
}

.react-confirm-alert-button-group button {
  width: 100px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #357ffa !important;
  border-color: #357ffa !important;
  font-weight: 500;
}

.react-confirm-alert-button-group button:last-child {
  width: 100px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d52929 !important;
  border-color: #d52929 !important;
  font-weight: 500;
}

span.err-input {
  position: absolute;
  left: 5px;
  bottom: -2px;
  font-size: 12px;
  color: #d52929;
}

.form-control.error {
  border-color: #d52929 !important;
}

.custom-header {
  height: 60px;
  display: flex;
  align-items: center;
  top: 11px;
  position: relative;
}
.custom-header span {
  font-size: 14px;
  font-weight: 600;
  color: #383850;
}

/* Overlay CSS */
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: flex; /* Hidden by default */
  min-width: 35%;
  width: 491px; /* Full width (cover the whole page) */
  /* height: 100%; Full height (cover the whole page)  */
  top: 124px;
  right: 0;
  bottom: 0;
  background: #ffffff; /* white background*/
  box-shadow: 0px 4px 39px rgba(0, 0, 0, 0.06);
  z-index: 1000; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  transform: translateX(0%);
  transition-timing-function: ease-in;
  transition: 0.8s;
}

.overlayhide {
  /* display: none !important; */
  min-width: 0% !important;
  width: 0px !important;
  transform: translateX(130%) !important ;
  transition-timing-function: ease-out !important;
  transition: 0.8s !important;
}

.form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.form-wrapper .form-header {
  width: 100%;
  border-top: 5px solid #3498db;
  padding: 17px 14px;
  background: #0079c1;
}

.form-wrapper .form-header span {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  display: flex;
  align-items: center;
  color: #ffffff;
}

.form-body {
  overflow-y: scroll;
  margin-top: 10px;
  height: 70vh;
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
}

.th-bold th {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #333;
}

.td-highlight {
  color: #0079c1 !important;
}

.close-image {
  text-align: right;
}

.disabled-link {
  pointer-events: none;
  background: rgba(242, 104, 42, 0.3) !important;
}

.td-pointer {
  cursor: pointer;
}

.greenTheme {
  background-color: #0079c1;
}

.redTheme {
  background-color: #dc484b;
}

.greenTheme img,
.redTheme img {
  width: 100%;
  height: 30px;
  margin: 0 auto;
}

.modal-body-items {
  text-align: center;
}

.background-grey {
  background-color: #e9ecf1 !important;
}

.norecords {
  text-align: center;
  padding: 10px;
  width: 100%;
  font-weight: bold;
}

.page-item.active .page-link {
  color: #000000 !important;
  border-color: #E6FB04 !important;
  background-color: #E6FB04 !important;
}

.page-item .page-link{
  color: #000;
}

.page-item .page-link:hover{
  background: rgb(230 251 4 / 0.2);
  color: #000000;
}

.page-item .page-link:focus {
  box-shadow: none;
}

.dropdown-menu.show {
  display: block;
  background: #000000;
}
.dropdown-menu.show .dropdown-item{margin-bottom: 1px;}
.dropdown-menu.show .dropdown-item:hover, .dropdown-menu.show .dropdown-item.active {
  background: rgb(230 251 4 / 41%) !important;
  color: #fff !important;
}
.dropdown-menu.show .dropdown-item{ color: #ffffff;}


.dropdown-item {
  padding: 1rem !important;
}

.nav-item {
  padding: 6px !important;
}

.nav-item .logout-icon{
  position: relative;
  top: -3px;
}

.navbar {
  padding: 0px !important;
}
.nav-item .nav-link{
  color: #E6FB04;
}

.login-nav-link{
  color: #E6FB04;
  font-size: 15px !important;
  padding: 15px 12px;
}

.nav-item.active {
  background-color: #E6FB04 !important; 
}
.nav-item.active a{color: #000000;}
.logout-icon {
  padding: 14px;
}

.blackButton {
  background: black !important;
}

.accountcreationLogo {
  padding-right: 15px;
}

.hide{
  display: none;
}

/* .whiteButton{
  background-color: white;
  color: black;
} */

/* Export Icon*/

#registerTip {
  background-color: #2e86c1;
}
.export-icon {
  border-radius: 100px;
  margin-right: 5px;
  border: 1px solid #dddddd;
  background-color: #edededa8;
}
.export-icon:hover{
  background-color: #ffffff;
}
.export-icon img {
  width: 33px;
  height: auto;
  cursor: pointer;
  margin-top: 0px;
  opacity: 0.6;
  padding: 4px;
}

/* Popup Modal */
.popup {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}
.overlay {
  width: 100vw;
  height: 100vh;
  top: 8.5%;
  left: 0;
  position: fixed;
  background: rgba(7, 7, 7, 0.6);
}
.popup-content {
  position: absolute;
  top: 320px;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  padding: 14px 28px;
  width: 307px;
  height: 184px;
  border-radius: 10px 10px 10px 10px;

  font-family: Roboto;
  font-style: normal;
}
.popup-header {
  position: absolute;
  width: 307px;
  height: 50px;
  left: 0px;
  top: 0px;
  
  background: #FFFFFF;
  border-bottom: solid #e4dfdf 1px;
  border-radius: 10px 10px 0px 0px;
}
.popup-header p {
  position: absolute;
  font-family: "Open Sans", helvetica, arial, sans-serif;
  width: 307px;
  height: 24px;
  left: 19px;
  top: 16px; 
  
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  
  color: #333333;
}

.popup-body {
  position: absolute;
  width: 86%;
  height: 54%;
  top: 73px;
  left: 27px;
  background: #fff;
}
.radio-form {
  font-family: "Open Sans", helvetica, arial, sans-serif;
  width: 307px;
  height: 27px;
  
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  
  align-items: center;
  letter-spacing: 1px;
  
  color: #333333;
}

.radio-form input {
  margin-right: 10px;
}

.loadingBar {
  color: red !important;
}

.btn-export {
    position: absolute;
    width: 255px;
    height: 33px;
    top: 64px;
    padding-top: 4px !important;
    padding-left: 10px !important;
    font-family: "Open Sans", helvetica, arial, sans-serif;
    letter-spacing: 1px;

    color: #fff !important;
    background: linear-gradient(180deg, #3498db 0%, #3392d1 100%);
    border-radius: 4px;
}

.close-popup {
  position: absolute;
  top: 16px;
  margin-left: 250px;
  width: 28px;
  padding: 5px 7px;
  color: #4D4D4D;
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity:0.8;
}

/* Search button */
.search-input {
  width: 360px;
  height: 34px;
  padding: 0 23px;
  margin-right: -21px;
  border-radius: 5px;

  border: 1px solid #dddddd;
}

.mandatory img {
  left: 20px;
}


#search-id input[type="search"]:hover {
  background-color: #fff;
}
#search-id input[type="search"]:focus {
  width: 360px;
  padding-left: 32px;
  color: #000;
  background-color: #fff;
  cursor: auto;
}
#search-id input:-moz-placeholder {
  color: transparent;
}
#search-id input::-webkit-input-placeholder {
  color: transparent;
}

input[type="search"] {
  background: #fff
    url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat
    9px center;
  border: solid 1px #ccc;
  padding: 9px 10px 9px 32px;
  

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="search"]:focus {
  width: 130px;
  background-color: #fff;
  border-color: #ccc;

  -webkit-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  -moz-box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
  box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}

/* Login Page */

.login-mainpage {
  padding-left: 50px !important;
  width: 100% !important;
  max-width: 1000px !important;
  background-color: #fff !important;
  padding-bottom: 60px !important;
  margin: auto !important;
  box-shadow: 0 0 4px #ddd;
  font-family: "Raleway", sans-serif !important;
}

.login-form {
  left: 25px;
}
.login-text {
  padding-top: 40px;
  padding-left: 110px !important;
  padding-right: 59px !important;
  width: 40px !important;
  text-align: justify;
}

/* Login Page Text */

.signin-articles h2 {
  margin-top: 0;
  padding-top: 0;
  color: #999;
  text-align: center;
  font-size: 30px;
}

.signin-articles ul li {
  padding-top: 15px;
  padding-bottom: 10px;
  line-height: 1.6em;
  text-align: justify;
}

.signin-articles a {
  font-weight: bold;
  color: #000000 !important;
}

.list-unstyled p {
  padding-bottom: 20px;
  color: #000;
  font-size: 14px !important ;
}

.drop-down-item {
  padding: 16px 10px !important;
}
.MuiSwitch-root {
  width: 37px !important;
  height: 21px !important;
  border-radius: 50px !important;
  padding: 0px !important;
}
.MuiSwitch-colorSecondary {
  padding: 0 5px !important; ;
  bottom: 0 !important;
}
.MuiSwitch-thumb {
  width: 10px !important;
  height: 10px !important;
  margin: 1px 0;
  color:#ffffff !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #4cd65e !important;
  opacity: 1 !important;
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #ffffff;
  padding: 0px 2px!important;
  bottom: 0;
  margin-left: 0;
}

.authModal.persistenceModal.cardModal.modal .modal-content {
  padding: 25px 25px 20px;
  border-radius: 16px !important;
}
.authModal.persistenceModal.cardModal.modal .modal-header.opt-header1 {
  padding: 0;
  width:100%;
}
h4.opt-header {
  color: #595959;
  font-weight: 700;
  font-size: 20px!important;
  line-height: 24px;
}
.datepicker-new {
  padding-top: 1rem!important;
  width:100%;
}
.react-datepicker__input-container input{
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  height: 40px;
  box-sizing: border-box;
  font-size: 14px;
  color: #595959;
  font-weight: 400;
  font-family: "Roboto",sans-serif;
  width:100%;
  text-indent: 10px;
}
.authModal.persistenceModal.cardModal.modal .modal-footer {
  padding: 20px 0 0;
  width: 100%;
  display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
}
.skipbutton {
  padding: 0;
  border: 1px solid #828282;
  color: #595959 !important;
  background: transparent;
  border-radius: 4px;
  font-size: 14px;
  height: 32px;
  min-width: 74px;
  text-align: center;
  font-family: "Roboto",sans-serif;
  font-weight: 400;
  font-style: normal;
}
.blue-button-new {
  background: #0079c3!important;
  border: 1px solid #0079c3;
  color: #fff;
  border-radius: 4px;
  height: 32px;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  min-width: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}
.close-icon {
  position: absolute;
  right: 5px;
  top: 2px;
}
.close-icon  img{
  width:24px;
}

.defalut-loader-screen {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  flex-direction: column;
}

.defalut-loader-screen img{
  width:40px;
}

.defalut-loader-screen h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #595959;
  margin-top: 25px;
}

.defalut-loader-screen p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #595959;
  margin-top: 15px;
}

.sort-up{position: relative;top: 5px;}

.nav-item button.nav-link{border: 0; background: transparent;}
.iframe{width: 100%; min-height: 750px; height: 100%; border: 0;}
.all-screens-head {
  align-items: center;
  display: flex;
  margin-left: 0px;
  position: relative;
}

.all-screens-head span {
  padding-left: 10px;
  color: #E6FB04;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 2px;
}

/* Enable Zingit  */
.flyout-outer{position: relative;}
.flyout{position: absolute; background: #fff; padding: 15px 15px; border: 1px solid #000000; border-radius: 5px; z-index: 10; width: 330px; box-shadow: 0px 4px 12px -2px #d8d8d8;
  top: 7px;}
.flyout.arrow:after { content: " "; position: absolute; border: solid #000000; border-width: 0 1px 1px 0; display: inline-block; padding: 4px; transform: rotate(-135deg);  -webkit-transform: rotate(-135deg);
  top: -5.1px;  background: #fff;}
.flyout .form-control{height:35px; font-size: 11px; padding:.375rem 0.45rem;}
.flyout .btn{ font-size: 12px; padding: 4px 10px; margin: 0px 0px 0px 5px; line-height: 25px; }
.flyout .btn:hover{opacity: 0.9;}
.flyout .btn.btn-primary{ color: #fff !important; background: linear-gradient(180deg, #3498db 0%, #3392d1 100%); border-radius: 4px; border-color: #3498db;}

.flyout .btn.btn-secondary{background: #9f9f9f; border-color: #9f9f9f; color: #ffffff;}
.flyout .form-control.error{border-color: red;}

.toggle-info{display: flex; justify-content: space-between;}
.toggle-info .info-icon{margin-left: 5px; cursor: pointer; width: 18px;}
.toggle-info .info-icon.disabled{opacity: 0.3;}
.toggle-info .flyout-outer .flyout{right: -9px; left: auto; top: 10px;}
.toggle-info .flyout-outer .flyout.arrow::after{right:12px ; }